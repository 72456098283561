<template>
 <v-dialog
    v-model="dialogInput"
    persistent
    :retain-focus="false"
    width="800"
  >
    <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2">{{titulo}}</p>
      <v-form
        ref="form"
      >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
            label="Rut Empresa"
            v-model="rut"
            style="margin-bottom: -5px;"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
            label="Razón Social"
            v-model="razonSocial"
            style="margin-bottom: -5px;"
            ></v-text-field>
          </v-col>
        </v-row>
        <p class="headline primary--text font-weight-regular mt-2">Usuario Administrador</p>
        <UsuarioSelect :usuario.sync="usuario"/>
        <p class="headline primary--text font-weight-regular mt-2">Sucursal Principal</p>
        <SucursalSelect :sucursal.sync="sucursal"/>
        <v-row justify="center">
          <v-btn
            color="primary"
            @click="mostrar"
          >
          GUARDAR
          </v-btn>
        </v-row>
      </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import UsuarioSelect from '@/components/usuarioselect.vue'
import SucursalSelect from '@/components/sucursalselect.vue'

export default {
  data: () => ({
    titulo: 'Datos Base',
    rut: '',
    razonSocial: '',
    usuario: '',
    sucursal: ''
  }),
  props: ['modo', 'dialogDB'],
  components: {
    UsuarioSelect,
    SucursalSelect
  },
  methods: {
    reset () {
      this.rut = ''
      this.razonSocial = ''
      this.usuario = ''
      this.sucursal = ''
    },
    datos () {
      this.rut = '11.111.111-1'
      this.razonSocial = 'AFLAB LTDA.'
      this.usuario = 'Arturo Fernández Espinoza'
      this.sucursal = 'AFLAB LTDA.'
    },
    guardarDatos () {
      //
    },
    mostrar () {
      alert(this.usuario)
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogDB
      },
      set: function (newValue) {
        this.$emit('update:dialogDB', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialogDB () {
      if (this.dialogDB === false) {
      } else {
        //
      }
    },
    modo () {
      if (this.modo === 'EDITAR') {
        this.datos()
      } else {
        this.reset()
      }
    }
  }
}
</script>
