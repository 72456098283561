<template>
   <v-row no-gutters class="text-center rounded-10" wrap align="center">
    <v-col
      cols="12"
      sm="12"
    >
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <p class="display-2 primary--text font-weight-regular mt-4">{{titulo}}</p>
        <v-row class="text-left pl-5 pr-5 mb-5">
          <v-col
            cols="12"
            sm="6"
          >
            <div class="headline pl-2 pr-2 mb-1 pt-2 pb-2" style="border: 1px solid Gainsboro" v-for="tag in datos" v-bind:key="tag.id">
              <p class="primary--text" style="display: inline;">{{ tag.campo }}:&nbsp;</p>
              <p class="black--text" style="display: inline;">{{ tag.resultado }}</p>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <div class="headline pl-2 pr-2 mb-1 pt-2 pb-2" style="border: 1px solid Gainsboro" v-for="tag in datos2" v-bind:key="tag.id">
              <p class="primary--text" style="display: inline;">{{ tag.campo }}:&nbsp;</p>
              <p class="black--text" style="display: inline;">{{ tag.resultado }}</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      width="350"
      persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          titulo
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
            style="padding: 10px;"
          >
          contenido
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    inputsizq: [],
    inputsder: [],
    dialog: false,
    showpass: false,
    showpass2: false,
    botonpass: false
  }),
  props: ['modo', 'titulo', 'datos', 'datos2'],
  methods: {
    cargarDatos () {
      //
    },
    volver () {
      if (this.modo === 'fichapaciente') {
        this.$router.push('pacientes')
      }
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>
