<template>
  <div id="laboratorios">
    <MenuBase
      :tipo="'laboratorios'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-home'"
    />
    <Sucursal :modo.sync="modoSucursal" :dialogSu.sync="dialogSucursal"/>
    <RegistrarLaboratorio :modo.sync="modoDatosBase" :dialogDB.sync="dialogDatosBase"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Sucursal from '@/views/sucursal.vue'
import RegistrarLaboratorio from '@/views/registrarlaboratorio.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Sucursal,
    RegistrarLaboratorio
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Laboratorios y Sucursales',
    dialog: false,
    tituloOpciones: 'Opciones sucursal: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoSucursal: 'NUEVA',
    dialogSucursal: false,
    dialogDatosBase: false,
    modoDatosBase: ''
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'RUT', align: 'start', value: 'rut_lab' },
        { text: 'NOMBRE', align: 'start', value: 'nombre_lab' },
        { text: 'TIPO', align: 'start', value: 'tipo_lab' },
        { text: 'ES TOMA MUESTRAS', align: 'start', value: 'estoma_lab' }

      ]
      this.botones = [
        { id: 2, nombre: 'DATOS BASE', color: 'primary', accion: 'datosBase' },
        { id: 1, nombre: 'NUEVA SUCURSAL', color: 'primary', accion: 'nuevaSucursal' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR SUCURSAL', color: 'primary', accion: 'editarSucursal' },
        { id: 2, nombre: 'QUITAR SUCURSAL', color: 'error', accion: 'quitarSucursal' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_lab
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevaSucursal') {
        this.modoSucursal = 'NUEVA'
        this.dialogSucursal = true
      } else if (accion === 'editarSucursal') {
        this.modoSucursal = 'EDITAR'
        this.dialogSucursal = true
      } else if (accion === 'quitarSucursal') {
      } else if (accion === 'datosBase') {
        this.modoDatosBase = 'EDITAR'
        this.dialogDatosBase = true
      }
    },
    cargarItems () {
      this.items = [
        { idlaboratorio: 1, rut_lab: '11.111.111-1', nombre_lab: 'AFLAB LTDA.', tipo_lab: 'Casa matriz, con toma muestras y procesamiento', estoma_lab: 'SI' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
