import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index.vue'
import Menu from '../views/Menus/menu.vue'
import Pacientes from '../views/Menus/pacientes.vue'
import InformesExamenes from '../views/Menus/informesexamenes.vue'
import Examenes from '../views/Menus/examenes.vue'
import PlantillasExamen from '../views/Menus/plantillasexamen.vue'
import TiposExamen from '../views/Menus/tiposexamen.vue'
import Muestras from '../views/Menus/muestras.vue'
import Reactivos from '../views/Menus/reactivos.vue'
import Metodos from '../views/Menus/metodos.vue'
import Medidas from '../views/Menus/medidas.vue'
import Laboratorios from '../views/Menus/laboratorios.vue'
import Usuarios from '../views/Menus/usuarios.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'index', component: Index },
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  { path: '/menu', name: 'menu', component: Menu },
  { path: '/pacientes', name: 'pacientes', component: Pacientes },
  { path: '/informesexamenes', name: 'informesexamenes', component: InformesExamenes },
  { path: '/examenes', name: 'examenes', component: Examenes },
  { path: '/plantillasexamen', name: 'plantillasexamen', component: PlantillasExamen },
  { path: '/tiposexamen', name: 'tiposexamen', component: TiposExamen },
  { path: '/muestras', name: 'muestras', component: Muestras },
  { path: '/reactivos', name: 'reactivos', component: Reactivos },
  { path: '/metodos', name: 'metodos', component: Metodos },
  { path: '/medidas', name: 'medidas', component: Medidas },
  { path: '/laboratorios', name: 'laboratorios', component: Laboratorios },
  { path: '/usuarios', name: 'usuarios', component: Usuarios }
]

const router = new VueRouter({
  routes
})

export default router
