<template>
  <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
    >
      <v-card class="mt-2" style="border-radius: 0px; background-color: cornflowerblue;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogInput = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
      </v-card>
    <PacienteUsuario
      :modo="'editarpaciente'"
      :titulo="'Editar Ficha Paciente'"
      :datos="datos"
    />
  </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'
import PacienteUsuario from '@/components/pacienteusuario.vue'

export default {
  data: () => ({
    datos: [
      { rut: '16.141.741-6', nombre: 'Bastian', segnombre: '', appaterno: 'Fernández', apmaterno: 'Palma', sexo: 'Masculino', edad: '36', fechanac: '18-04-1985', direccion: 'Pasaje Ingeniero Julio Velasco 281', comuna: 'Coquimbo', sector: 'Sindempart', email: 'bastian1232@hotmail.com', celular: '962777330', prevision: 'Fonasa', pass: '1234', repass: '1234' }
    ]
  }),
  mixins: [
    Globales
  ],
  components: {
    PacienteUsuario
  },
  props: ['dialogEP'],
  methods: {
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogEP
      },
      set: function (newValue) {
        this.$emit('update:dialogEP', newValue)
      }
    }
  },
  watch: {
    dialogEP () {
      if (this.dialogEP === false) {
        //
      }
    }
  }
}
</script>
