<template>
  <v-app>
    <v-main>
      <v-app-bar
        color="primary"
        dark
        v-show="$store.state.menuvisible"
      >
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

        <v-toolbar-title>{{ nombreapp }}</v-toolbar-title>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="primary"
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="deep-blue--text text--accent-4"
          >
            <v-list-item v-for="item in $store.state.itemsmenu" v-bind:key="item.nombre" :to="item.goto">
              <v-list-item-icon>
                <v-icon class="white--text">{{item.icono}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="white--text">{{item.nombre}}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-container>
        <router-view></router-view>
      </v-container>
      <v-footer
        padless
        fixed
      >
        <v-card
          class="flex primary"
          flat
          tile
        >
          <v-card-text class="py-2 white--text text-center">
            {{ new Date().getFullYear() }} &copy; <strong>BastWare EIRL</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueSession from 'vue-session'

Vue.use(VueSession)

export default {
  data: () => ({
    drawer: false,
    group: null,
    nombreapp: 'AFLAB WEB'
  })
}
</script>
