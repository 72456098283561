<template>
  <div id="metodos">
    <MenuBase
      :tipo="'metodos'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-check'"
    />
    <Metodo :modo="modoMetodo" :dialogMET.sync="dialogMetodo"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Metodo from '@/views/metodo.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Metodo
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Métodos',
    dialog: false,
    tituloOpciones: 'Opciones método: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoMetodo: 'NUEVO',
    dialogMetodo: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_metodo' },
        { text: 'NOTAS', align: 'start', value: 'notas_metodo' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO MÉTODO', color: 'primary', accion: 'nuevoMetodo' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR MÉTODO', color: 'primary', accion: 'editarMetodo' },
        { id: 2, nombre: 'QUITAR MÉTODO', color: 'error', accion: 'quitarMetodo' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_metodo
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoMetodo') {
        this.modoMetodo = 'NUEVO'
        this.dialogMetodo = true
      } else if (accion === 'editarMetodo') {
        this.modoMetodo = 'EDITAR'
        this.dialogMetodo = true
      } else if (accion === 'quitarMetodo') {
        alert('Se quitará éste método como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.items = [
        { idmetodo: 1, nombre_metodo: 'Algo 1', notas_metodo: 'dask djlaks jdlkasj dlas' },
        { idmetodo: 2, nombre_metodo: 'Algo 2', notas_metodo: 'sañldj alskjd lkasjdlkaj dklasj' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
