<template>
  <div id="pacientes">
    <MenuBase
      :tipo="'pacientes'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-account'"
    />
    <VerFicha :dialogVF.sync="dialogVerFicha"/>
    <NuevoPaciente :dialogNP.sync="dialogNuevoPaciente"/>
    <EditarPaciente :dialogEP.sync="dialogEditarPaciente"/>
    <AsignarExamenes :dialogAE.sync="dialogAsignarExamenes"/>
    <RecibirMuestras :dialogRM.sync="dialogRecibirMuestras"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import VerFicha from '@/views/Paciente/fichapaciente.vue'
import NuevoPaciente from '@/views/Paciente/nuevopaciente.vue'
import EditarPaciente from '@/views/Paciente/editarpaciente.vue'
import AsignarExamenes from '@/views/Paciente/asignarexamenes.vue'
import RecibirMuestras from '@/views/Paciente/recibirmuestras.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    VerFicha,
    NuevoPaciente,
    EditarPaciente,
    AsignarExamenes,
    RecibirMuestras
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Pacientes',
    tituloOpciones: 'Opciones Paciente: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogVerFicha: false,
    dialogNuevoPaciente: false,
    dialogEditarPaciente: false,
    dialogAsignarExamenes: false,
    dialogRecibirMuestras: false
  }),
  methods: {
    interfaz () {
      this.titulo = 'Pacientes'
      this.headers = [
        { text: 'RUT', align: 'start', value: 'rut_paciente' },
        { text: 'NOMBRE', align: 'start', value: 'nombre_paciente' },
        { text: 'EDAD', align: 'start', value: 'edad_paciente' },
        { text: 'SEXO', align: 'start', value: 'sexo_paciente' },
        { text: 'COMUNA', align: 'start', value: 'nombre_comuna' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO PACIENTE', color: 'primary', accion: 'nuevoPaciente' }
      ]
      this.opciones = [
        { id: 1, nombre: 'VER FICHA', color: 'primary', accion: 'verFicha' },
        { id: 2, nombre: 'ASIGNAR EXÁMENES', color: 'primary', accion: 'asignarExamenes' },
        { id: 3, nombre: 'RECIBIR MUESTRAS', color: 'primary', accion: 'recibirMuestras' },
        { id: 4, nombre: 'INFORMAR EXÁMENES', color: 'primary', accion: 'informarExamenesPaciente' },
        { id: 5, nombre: 'EDITAR FICHA', color: 'primary', accion: 'editarFicha' },
        { id: 6, nombre: 'HISTORIAL EXÁMENES', color: 'primary', accion: 'historialExamenesPaciente' }
      ]
    },
    clicBoton (accion) {
      if (accion === 'nuevoPaciente') {
        this.dialogNuevoPaciente = true
      } else if (accion === 'verFicha') {
        this.dialogVerFicha = true
      } else if (accion === 'asignarExamenes') {
        this.dialogAsignarExamenes = true
      } else if (accion === 'recibirMuestras') {
        this.dialogRecibirMuestras = true
      } else if (accion === 'informarExamenesPaciente') {
        this.$router.push('/informesexamenes')
        // carga de datos en destino de examenes del paciente no informados
      } else if (accion === 'editarFicha') {
        this.dialogEditarPaciente = true
      } else if (accion === 'historialExamenesPaciente') {
        this.$router.push('/informesexamenes')
        // carga de datos en destino de examenes del paciente
      }
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_paciente
    },
    cargarItems () {
      this.items = [
        { idpaciente: 1, rut_paciente: '16.141.741-6', nombre_paciente: 'Bastian Fernández Palma', edad_paciente: '35', sexo_paciente: 'Masculino', nombre_comuna: 'Coquimbo' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
