<template>
   <v-row no-gutters class="text-center" wrap align="center">
    <v-col
      cols="12"
      sm="12"
    >
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <p class="display-2 primary--text font-weight-regular mt-4">{{titulo}}</p>
        <v-form
          ref="form"
        >
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="RUT"
              v-model="datos[0].rut"
              @change="esRut"
              :rules="regla12Letras"
              :counter="12"
              style="margin-bottom: -5px;"
              required
            ></v-text-field>
            <v-text-field
              label="Nombre"
              v-model="datos[0].nombre"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
              required
            ></v-text-field>
            <v-text-field
              label="Segundo Nombre"
              v-model="datos[0].segnombre"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Apellido Paterno"
              v-model="datos[0].appaterno"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Apellido Materno"
              v-model="datos[0].apmaterno"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datos[0].fechanac"
                  label="Fecha de Nacimiento"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  v-show="fechanacvisible"
                  style="margin-bottom: -5px;"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                locale="es-CL"
                :first-day-of-week="1"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-autocomplete
              label="Sexo"
              :items="sexos"
              v-model="datos[0].sexo"
              clearable
              style="margin-bottom: -5px;"
              required
            ></v-autocomplete>
            <SucursalSelect v-show="botonpass" :sucursal.sync="sucursal"/>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Email"
              v-model="datos[0].email"
              :rules="regla45Letras"
              :counter="45"
              @change="emailUnico"
              style="margin-bottom: -5px;"
              required
            ></v-text-field>
            <v-text-field
              label="Celular"
              v-model="datos[0].celular"
              :rules="regla12Letras"
              :counter="12"
              style="margin-bottom: -5px;"
              type="number"
            ></v-text-field>
            <v-text-field
              label="Previsión"
              v-model="datos[0].prevision"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-autocomplete
              label="Comuna"
              :items="comunas"
              v-model="datos[0].comuna"
              clearable
              style="margin-bottom: -5px;"
            ></v-autocomplete>
            <v-text-field
              label="Dirección"
              v-model="datos[0].direccion"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-text-field
              label="Sector"
              v-model="datos[0].sector"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
            ></v-text-field>
            <v-btn
              color="primary"
              class="mt-3"
              @click="dialog = true"
              v-show="botonpass"
            >
            EDITAR CONTRASEÑA
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="space-around">
          <v-btn
            color="primary"
            class="mt-3"
            @click="guardarDatos"
            style="margin-bottom: 40px;"
          >
          {{nombreboton}}
          </v-btn>
        </v-row>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      width="350"
      persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          EDITAR CONTRASEÑA
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
            style="padding: 10px;"
          >
            <v-text-field
              label="Contraseña"
              v-model="datos[0].pass"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: -5px;"
              :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showpass ? 'text' : 'password'"
              @click:append="showpass = !showpass"
            ></v-text-field>
            <v-text-field
              label="Sector"
              v-model="datos[0].repass"
              :rules="regla45Letras"
              :counter="45"
              style="margin-bottom: 10px;"
              :append-icon="showpass2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showpass2 ? 'text' : 'password'"
              @click:append="showpass2 = !showpass2"
            ></v-text-field>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import SucursalSelect from '@/components/sucursalselect.vue'

export default {
  data: () => ({
    inputsizq: [],
    inputsder: [],
    regla45Letras: [
      v => !!v || 'Este campo es obligatorio',
      v => v.length <= 45 || 'Se acepta un máximo de 45 caracteres'
    ],
    regla12Letras: [
      v => !!v || 'Este campo es obligatorio',
      v => v.length <= 12 || 'Se acepta un máximo de 12 caracteres'
    ],
    date: new Date().toISOString().substr(0, 10),
    menu1: false,
    fechanacvisible: true,
    sexos: ['Masculino', 'Femenino'],
    emailRules: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/.test(v) || 'El email debe ser válido'],
    comunas: ['La Ligua', 'Coquimbo'],
    nombreboton: '',
    dialog: false,
    showpass: false,
    showpass2: false,
    botonpass: false,
    sucursal: ''
  }),
  components: {
    SucursalSelect
  },
  props: ['modo', 'titulo', 'datos'],
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      if (this.modo === 'nuevopaciente') {
        this.nombreboton = 'REGISTRAR'
      } else if (this.modo === 'editarpaciente') {
        this.nombreboton = 'GUARDAR'
      } else if (this.modo === 'nuevousuario') {
        this.nombreboton = 'REGISTRAR'
        this.botonpass = true
      } else if (this.modo === 'editarusuario') {
        this.nombreboton = 'GUARDAR'
        this.botonpass = true
      }
    },
    esRut () {
      alert('es rut')
    },
    emailUnico () {
      //
    },
    noAccion () {
      //
    },
    volver () {
      if (this.modo === 'nuevopaciente' | this.modo === 'editarpaciente') {
        this.$router.push('pacientes')
      }
    }
  },
  created: function () {
    this.cargarDatos()
  },
  watch: {
    date (val) {
      this.datos[0].fechanac = this.formatDate(this.date)
    }
  }
}
</script>
