<template>
  <div id="muestras">
    <MenuBase
      :tipo="'muestras'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-water'"
    />
    <Muestra :modo="modoMuestra" :dialogMU.sync="dialogMuestra"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Muestra from '@/views/muestra.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Muestra
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Muestras',
    dialog: false,
    tituloOpciones: 'Opciones muestra: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoMuestra: 'NUEVA',
    dialogMuestra: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_muestra' },
        { text: 'NOTAS', align: 'start', value: 'notas_muestra' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVA MUESTRA', color: 'primary', accion: 'nuevaMuestra' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR MUESTRA', color: 'primary', accion: 'editarMuestra' },
        { id: 2, nombre: 'QUITAR MUESTRA', color: 'error', accion: 'quitarMuestra' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_muestra
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevaMuestra') {
        this.modoMuestra = 'NUEVA'
        this.dialogMuestra = true
      } else if (accion === 'editarMuestra') {
        this.modoMuestra = 'EDITAR'
        this.dialogMuestra = true
      } else if (accion === 'quitarMuestra') {
        alert('Se quitará ésta muestra como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.items = [
        { idmuestra: 1, nombre_muestra: 'Orina', notas_muestra: 'Orina en frasco.' },
        { idmuestra: 2, nombre_muestra: 'Sangre', notas_muestra: 'Sangre en tubos.' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
