<template>
  <v-row>
    <v-autocomplete
      label="Reactivo"
      :items="$store.state.reactivos"
      v-model="reactivoInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
    </v-btn>
    <Reactivo :modo.sync="modoReactivo" :dialogR.sync="dialogReactivo"/>
  </v-row>
</template>

<script>
import Reactivo from '@/views/reactivo.vue'

export default {
  data: () => ({
    modoReactivo: 'NUEVO',
    dialogReactivo: false
  }),
  props: ['reactivo'],
  components: {
    Reactivo
  },
  methods: {
    cargarNuevoEditar () {
      if (this.reactivoInput === '' | this.reactivoInput === null) {
        this.modoReactivo = 'NUEVO'
      } else {
        this.modoReactivo = 'EDITAR'
      }
      this.dialogReactivo = true
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var reactivos = ['Químico 1', 'Químico 2']
      this.$store.commit('cambiarReactivos', reactivos)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    reactivoInput: {
      get: function () {
        return this.reactivo
      },
      set: function (newValue) {
        this.$emit('update:reactivo', newValue)
      }
    }
  }
}
</script>
