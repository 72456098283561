<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    :retain-focus="false"
  >
  <v-card class="mt-2" style="border-radius: 0px; background-color: cornflowerblue;">
    <v-card-actions>
      <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
      <v-btn
        color="error"
        dark
        fab
        small
        @click="dialogInput = false"
      >
      <v-icon dark>
        mdi-close
      </v-icon>
      </v-btn>
    </v-row>
    </v-card-actions>
  </v-card>
    <FichaPacienteUsuario
      :modo="'fichapaciente'"
      :titulo="'Ficha Paciente'"
      :datos="datos"
      :datos2="datos2"
    />
  </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'
import FichaPacienteUsuario from '@/components/fichapacienteusuario.vue'

export default {
  data: () => ({
    datos: [
      { campo: 'RUT', resultado: '16.141.741-6' },
      { campo: 'NOMBRE', resultado: 'Bastian' },
      { campo: 'SEG. NOMBRE', resultado: '' },
      { campo: 'AP. PATERNO', resultado: 'Fernández' },
      { campo: 'AP. MATERNO', resultado: 'Palma' },
      { campo: 'SEXO', resultado: 'Masculino' },
      { campo: 'EDAD', resultado: '36' }
    ],
    datos2: [
      { campo: 'FECHA NAC.', resultado: '18-04-1985' },
      { campo: 'DIRECCIÓN', resultado: 'Pasaje Ingeniero Julio Velasco 281' },
      { campo: 'COMUNA', resultado: 'Coquimbo' },
      { campo: 'SECTOR', resultado: 'Sindempart' },
      { campo: 'EMAIL', resultado: 'bastian1232@hotmail.com' },
      { campo: 'CELULAR', resultado: '962777330' },
      { campo: 'PREVISIÓN', resultado: 'Fonasa' }
    ]
  }),
  mixins: [
    Globales
  ],
  components: {
    FichaPacienteUsuario
  },
  props: ['dialogVF'],
  methods: {
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogVF
      },
      set: function (newValue) {
        this.$emit('update:dialogVF', newValue)
      }
    }
  },
  watch: {
    dialogVF () {
      if (this.dialogVF === false) {
        //
      }
    }
  }
}
</script>
