<template>
  <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
    >
    <v-card class="mt-2" style="border-radius: 0px; background-color: cornflowerblue;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <PacienteUsuario
      :modo="'nuevopaciente'"
      :titulo="'Nuevo Paciente'"
      :datos="datos"
    />
  </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'
import PacienteUsuario from '@/components/pacienteusuario.vue'

export default {
  data: () => ({
    datos: [
      { rut: '', nombre: '', segnombre: '', appaterno: '', apmaterno: '', sexo: '', edad: '', fechanac: '', direccion: '', comuna: '', sector: '', email: '', celular: '', prevision: '', pass: '', repass: '' }
    ]
  }),
  mixins: [
    Globales
  ],
  components: {
    PacienteUsuario
  },
  props: ['dialogNP'],
  methods: {
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogNP
      },
      set: function (newValue) {
        this.$emit('update:dialogNP', newValue)
      }
    }
  },
  watch: {
    dialogNP () {
      if (this.dialogNP === false) {
        //
      }
    }
  }
}
</script>
