<template>
  <v-row>
    <v-autocomplete
      label="Muestras"
      :items="$store.state.muestras"
      v-model="muestraInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
     </v-btn>
     <Muestra :modo.sync="modoMuestra" :dialogMU.sync="dialogMuestra"/>
  </v-row>
</template>

<script>
import Muestra from '@/views/muestra.vue'

export default {
  data: () => ({
    modoMuestra: 'NUEVA',
    dialogMuestra: false
  }),
  props: ['muestra'],
  components: {
    Muestra
  },
  methods: {
    cargarNuevoEditar () {
      if (this.muestraInput === '' | this.muestraInput === null) {
        this.modoMuestra = 'NUEVO'
      } else {
        this.modoMuestra = 'EDITAR'
      }
      this.dialogMuestra = true
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var muestra = ['Sangre', 'Orina']
      this.$store.commit('cambiarMuestras', muestra)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    muestraInput: {
      get: function () {
        return this.muestra
      },
      set: function (newValue) {
        this.$emit('update:muestra', newValue)
      }
    }
  }
}
</script>
