<template>
   <v-row no-gutters class="text-center mt-3" wrap align="center">
    <v-col
      cols="12"
      sm="12"
    >
    <p class="display-2 primary--text font-weight-regular">
      <v-icon x-large class="primary--text">
        {{icono}}
      </v-icon>
      {{titulo}}</p>
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <v-form
          ref="form"
        >
        <v-row
            justify="center"
            class="mb-2"
          >
          <v-btn v-for="boton in botones" v-bind:key="boton.id"
            :color="boton.color"
            class="mt-5 mr-1"
            @click="clicBoton(boton.accion)"
            style="width: 200px;"
          >
            {{boton.nombre}}
          </v-btn>
          </v-row>
          <v-row
            justify="center"
            class="mb-2 mt-5 ml-2"
            v-show="multiselect"
          >
          <p>Seleccionados:</p>
          <v-chip-group
            column
          >
            <v-chip
              v-for="chips in multis"
              v-bind:key="chips"
              :value="chips"
              close
              @click:close="$emit('quitar-multi', chips)"
              class="primary"
              style="font-size: large;"
            >
              {{ chips }}
            </v-chip>
          </v-chip-group>
            <v-btn
              color="success"
              class="mt-5 mr-1"
              @click="clicBoton('confirmarEnvioMultiEmail')"
            >
            ENVIAR
            </v-btn>
            <v-btn
              color="primary"
              class="mt-5 mr-1"
              @click="clicBoton('cancelarMultiEmail')"
            >
            CANCELAR
            </v-btn>
          </v-row>
            <v-text-field
            v-model="filtrar"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
            v-show="filtrovisible"
          ></v-text-field>
          <v-data-table
            :headers="headers"
            :items="items"
            :search="filtrar"
            :mobile-breakpoint='0'
            @click:row="clicFila"
            class="mt-5"
          >
<!--           <template v-slot:header="{ props }">
            <th v-for="head in props.headers" v-bind:key="head.text" class="headline primary--text" :align="alineaHeaders">
              {{ head.text.toUpperCase() }}
            </th>
          </template> -->
          </v-data-table>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-dialog
      v-model="dialog"
      width="350"
      persistent
    >
      <v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="dialog = false"
          >
          X
          </v-btn>
        </v-card-actions>
        <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
          {{tituloOpciones}} {{campoOpcion}}
        </v-card-title>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
            class="mb-2"
          >
            <v-btn v-for="opcion in opciones" v-bind:key="opcion.id"
              :color="opcion.color"
              class="mt-5 mr-1"
              @click="clicBoton(opcion.accion)"
              style="width: 250px;"
            >
              {{opcion.nombre}}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    filtrar: '',
    filtrovisible: true,
    dialog: false
  }),
  props: ['tipo', 'headers', 'items', 'botones', 'opciones', 'alineaHeaders', 'titulo', 'tituloOpciones', 'campoOpcion', 'multiselect', 'multis', 'icono'],
  methods: {
    clicBoton (accion) {
      this.$emit('clic-boton', accion)
    },
    clicFila (fila) {
      this.$emit('clic-fila', fila)
      if (this.multiselect === true) {
        this.dialog = false
      } else {
        this.dialog = true
      }
    }
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
  .v-chip-group.v-chip-group.v-chip-group >>> .v-chip .v-chip__close.v-icon  {
    color: white;
  }
</style>
