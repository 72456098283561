<template>
  <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
    >
      <v-card class="mt-2" style="border-radius: 0px; background-color: cornflowerblue;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogInput = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
      </v-card>
    <v-row no-gutters class="text-center" wrap align="center">
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          class="mx-auto"
          outlined
          tile
          elevation="5"
        >
        <v-card-text>
          <p class="display-2 primary--text font-weight-regular">{{titulo}}</p>
          <p class="display-1 primary--text font-weight-regular" style="margin-top: -10px;">Paciente: {{paciente}}</p>
          <v-form
            ref="form"
          >
            <v-row align="center" justify="center">
              <v-btn
                color="primary"
                @click="dialog = true"
                class="mb-4 mr-1"
              >
                MODO IMPRESION ETIQUETAS
              </v-btn>
              <v-btn
                color="primary"
                class="mb-4"
                @click="etiquetasAntiguas"
              >
                ETIQUETAS ANTIGUAS
              </v-btn>
            </v-row>
            <v-row class="mb-5">
              <v-col
                cols="12"
                sm="6"
              >
                <v-list dense>
                  <v-subheader class="headline primary--text">Muestras pendientes</v-subheader>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in itemspendientes"
                      :key="i"
                      style="border: 1px solid Gainsboro"
                      @click="opcionesItems('pendientes', item.muestra)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.muestra" class="text-left subtitle-1"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-row align="center" justify="center" v-show="verbotonespendientes">
                  <v-btn
                    color="success"
                    class="mt-3 mr-2"
                    @click="agregarTodas"
                  >
                  AGREGAR TODAS
                  </v-btn>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <v-list dense>
                  <v-subheader class="headline primary--text">Muestras recibidas</v-subheader>
                  <v-list-item-group
                    v-model="selectedItem2"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, i) in itemsrecibidos"
                      :key="i"
                      style="border: 1px solid Gainsboro"
                      @click="opcionesItems('recibidas', item.muestra)"
                    >
                      <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.muestra" class="text-left subtitle-1"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <v-row align="center" justify="center" v-show="verbotonesrecibidas">
                  <v-btn
                    color="error"
                    class="mt-3 mr-2"
                    @click="quitarTodo"
                  >
                  QUITAR TODO
                  </v-btn>
                  <v-menu
                    offset-y
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        class="mt-3"
                      >
                        IMPRIMIR TODO
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, index) in items"
                        :key="index"
                        @click="imprTodo(index)"
                      >
                        <v-list-item-title class="primary--text" style="font-weight: bold;">{{ item.title }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" style="margin-bottom: 40px;">
              <v-btn
                color="primary"
                class="mt-3 mr-2"
                @click="guardarDatos"
              >
              GUARDAR
              </v-btn>
            </v-row>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog
        v-model="dialog"
        width="350"
        persistent
      >
        <v-card>
          <v-card-title class="headline blue--text justify-center text-center" style="word-break: break-word;">
            Modo de impresión de etiquetas
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
              align="center"
              style="padding: 10px;"
            >
              <v-checkbox
                v-model="check1"
                label="Etiqueta individual (impresora de etiquetas)"
                style="margin-bottom: -30px;"
                @change="selectCheck1"
              ></v-checkbox>
              <v-checkbox
                v-model="check2"
                label="Etiqueta impresora (varias etiquetas hoja carta)"
                @change="selectCheck2"
              ></v-checkbox>
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                OK
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogmuestras"
        width="350"
        persistent
      >
        <v-card>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="dialogmuestras = false"
            >
            X
            </v-btn>
          </v-card-actions>
          <v-card-title class="headline primary--text justify-center text-center" style="word-break: break-word;">
            Opciones muestra {{ tipomuestra }}: {{ tituloopciones }}
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
              align="center"
              class="mb-2"
            >
              <v-btn v-for="opcion in botonesopciones" v-bind:key="opcion.id"
                :color="opcion.color"
                class="mt-5 mr-1"
                @click="clicBoton(opcion.accion)"
                style="width: 250px;"
              >
                {{opcion.nombre}}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'

export default {
  data: () => ({
    titulo: 'Recibir Muestras',
    paciente: 'Bastian Fernández Palma',
    examenes: ['Glicemia', 'Perfil Bioquímico', 'Perfil Lipídico', 'Hemograma'],
    examenesok: [],
    medico: '',
    notas: '',
    dialog: false,
    check1: false,
    check2: false,
    selectedItem: null,
    itemspendientes: [
      { id: 0, muestra: 'Sangre 1 (N° Orden: 123 - 15-03-2021)', icon: 'mdi-water' },
      { id: 1, muestra: 'Sangre 2 (N° Orden: 123 - 15-03-2021)', icon: 'mdi-water' },
      { id: 2, muestra: 'Orina 1 (N° Orden: 123 - 15-03-2021)', icon: 'mdi-water' }
    ],
    itemsrecibidos: [
      { id: 0, muestra: 'No se han recibido muestras', icon: 'mdi-water' }
    ],
    selectedItem2: null,
    dialogmuestras: false,
    botonesopciones: [],
    tituloopciones: '',
    tipomuestra: '',
    verbotonesrecibidas: false,
    verbotonespendientes: true,
    items: [
      { title: 'CON CODIGO' },
      { title: 'CON DETALLE' }
    ]
  }),
  mixins: [
    Globales
  ],
  props: ['dialogRM'],
  methods: {
    selectCheck1 () {
      if (this.check1 === true) {
        this.check2 = false
      } else {
        this.check2 = true
      }
    },
    selectCheck2 () {
      if (this.check2 === true) {
        this.check1 = false
      } else {
        this.check1 = true
      }
    },
    opcionesItems (modo, tipo) {
      this.botonesopciones = []
      this.tituloopciones = tipo
      if (modo === 'pendientes') {
        this.tipomuestra = 'pendiente'
        this.botonesopciones = [
          { id: 1, nombre: 'Agregar Muestra', color: 'success', accion: 'agregarMuestra' },
          { id: 2, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' }
        ]
      } else if (modo === 'recibidas') {
        this.tipomuestra = 'recibida'
        this.botonesopciones = [
          { id: 1, nombre: 'Imprimir etiqueta código', color: 'primary', accion: 'impCod' },
          { id: 2, nombre: 'Imprimir etiqueta detalle', color: 'primary', accion: 'impDet' },
          { id: 3, nombre: 'Ver Exámenes Asociados', color: 'primary', accion: 'detallesExamenes' },
          { id: 4, nombre: 'Quitar Muestra', color: 'error ', accion: 'quitarMuestra' }
        ]
      }
      if (tipo !== 'No se han recibido muestras') {
        this.dialogmuestras = true
      }
    },
    quitarTodo () {
      if (this.itemspendientes[0].muestra === 'No hay muestras pendientes') {
        this.itemspendientes = []
        this.verbotonesrecibidas = false
        this.verbotonespendientes = true
      }
      var index = 0
      var items = this.itemsrecibidos.length
      while (items > index) {
        this.itemspendientes.push(this.itemsrecibidos[index])
        index++
      }
      this.itemsrecibidos = []
      this.sinRecibidas()
    },
    agregarTodas () {
      if (this.itemsrecibidos[0].muestra === 'No se han recibido muestras') {
        this.itemsrecibidos = []
        this.verbotonesrecibidas = true
        this.verbotonespendientes = false
      }
      var index = 0
      var items = this.itemspendientes.length
      while (items > index) {
        this.itemsrecibidos.push(this.itemspendientes[index])
        index++
      }
      this.itemspendientes = []
      this.sinPendientes()
    },
    sinPendientes () {
      if (this.itemspendientes.length === 0) {
        this.itemspendientes = [
          { id: 0, muestra: 'No hay muestras pendientes', icon: 'mdi-water' }
        ]
        this.verbotonespendientes = false
        this.verbotonesrecibidas = true
      }
    },
    sinRecibidas () {
      if (this.itemsrecibidos.length === 0) {
        this.itemsrecibidos = [
          { id: 0, muestra: 'No se han recibido muestras', icon: 'mdi-water' }
        ]
        this.verbotonespendientes = true
        this.verbotonesrecibidas = false
      }
    },
    imprTodo (index) {
      if (index === 0) {
        this.imprTodoCodigo()
      } else if (index === 1) {
        this.imprTodoDetalle()
      }
    },
    imprTodoCodigo () {
      alert('codigo')
    },
    imprTodoDetalle () {
      alert('detalle')
    },
    etiquetasAntiguas () {
      alert('Se mostrará tabla de registro de etiquetas ya impresas; existe en caso de que se pase la impresión de alguna o se requiera reimprimir')
    },
    cargarMuestras () {
      //
    },
    clicBoton (accion) {
      if (accion === 'agregarMuestra') {
        if (this.itemsrecibidos[0].muestra === 'No se han recibido muestras') {
          this.itemsrecibidos = []
          this.verbotonesrecibidas = true
        }
        this.itemsrecibidos.push(this.itemspendientes[this.selectedItem])
        this.itemspendientes.splice(this.selectedItem, 1)
        this.sinPendientes()
        this.selectedItem = null
        this.dialogmuestras = false
      } else if (accion === 'detallesExamenes') {
        alert('Se mostrarán exámenes asociados a la muestra')
      } else if (accion === 'impCod') {
        //
      } else if (accion === 'impDet') {
        //
      } else if (accion === 'quitarMuestra') {
        if (this.itemspendientes[0].muestra === 'No hay muestras pendientes') {
          this.itemspendientes = []
          this.verbotonespendientes = true
        }
        this.itemspendientes.push(this.itemsrecibidos[this.selectedItem2])
        this.itemsrecibidos.splice(this.selectedItem2, 1)
        this.sinRecibidas()
        this.selectedItem2 = null
        this.dialogmuestras = false
      }
    },
    guardarDatos () {
      //
    },
    volver () {
      this.$router.push('pacientes')
    },
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogRM
      },
      set: function (newValue) {
        this.$emit('update:dialogRM', newValue)
      }
    }
  },
  watch: {
    dialogRM () {
      if (this.dialogRM === false) {
        //
      }
    }
  }
}
</script>
