<template>
  <v-row>
    <v-autocomplete
      label="Usuario"
      :items="$store.state.usuarios"
      v-model="usuarioInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
    <v-btn
      color="primary"
      dark
      fab
      class="mr-2"
      @click="cargarNuevoEditar"
    >
    <v-icon dark>
      mdi-select-all
    </v-icon>
    </v-btn>
    <UsuarioNuevo :dialogNU.sync="dialogUsuarioNuevo"/>
    <UsuarioEditar :dialogEU.sync="dialogUsuarioEditar"/>
  </v-row>
</template>

<script>
import UsuarioNuevo from '@/views/Usuario/nuevousuario.vue'
import UsuarioEditar from '@/views/Usuario/editarusuario.vue'

export default {
  data: () => ({
    dialogUsuarioNuevo: false,
    dialogUsuarioEditar: false
  }),
  props: ['usuario'],
  components: {
    UsuarioNuevo,
    UsuarioEditar
  },
  methods: {
    cargarNuevoEditar () {
      if (this.usuarioInput === '' | this.usuarioInput === null) {
        this.dialogUsuarioNuevo = true
      } else {
        this.dialogUsuarioEditar = true
      }
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var usuarios = ['Bastian Fernández Palma', 'Arturo Fernández Espinoza']
      this.$store.commit('cambiarUsuarios', usuarios)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    usuarioInput: {
      get: function () {
        return this.usuario
      },
      set: function (newValue) {
        this.$emit('update:usuario', newValue)
      }
    }
  }
}
</script>
