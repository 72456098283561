import { render, staticRenderFns } from "./reactivos.vue?vue&type=template&id=289b806c&scoped=true&"
import script from "./reactivos.vue?vue&type=script&lang=js&"
export * from "./reactivos.vue?vue&type=script&lang=js&"
import style0 from "./reactivos.vue?vue&type=style&index=0&id=289b806c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289b806c",
  null
  
)

export default component.exports