<template>
  <v-row>
    <v-autocomplete
      label="Tipo Exámen"
      :items="$store.state.tiposexamen"
      v-model="tipoexamenInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
     </v-btn>
     <TipoExamen :modo.sync="modoTipoExamen" :dialogTE.sync="dialogTipoExamen"/>
  </v-row>
</template>

<script>
import TipoExamen from '@/views/tipoexamen.vue'

export default {
  data: () => ({
    modoTipoExamen: 'NUEVO',
    dialogTipoExamen: false
  }),
  props: ['tipoexamen'],
  components: {
    TipoExamen
  },
  methods: {
    cargarNuevoEditar () {
      if (this.tipoexamenInput === '' | this.tipoexamenInput === null) {
        this.modoTipoExamen = 'NUEVO'
      } else {
        this.modoTipoExamen = 'EDITAR'
      }
      this.dialogTipoExamen = true
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var tiposexamen = ['Bioquimico', 'Hormonal']
      this.$store.commit('cambiarTiposExamen', tiposexamen)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    tipoexamenInput: {
      get: function () {
        return this.tipoexamen
      },
      set: function (newValue) {
        this.$emit('update:tipoexamen', newValue)
      }
    }
  }
}
</script>
