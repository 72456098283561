<template>
  <div id="reactivos">
    <MenuBase
      :tipo="'reactivos'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-chemical-weapon'"
    />
    <Reactivo :modo="modoReactivo" :dialogR.sync="dialogReactivo"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Reactivo from '@/views/reactivo.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Reactivo
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Reactivos',
    dialog: false,
    tituloOpciones: 'Opciones reactivo: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoReactivo: 'NUEVO',
    dialogReactivo: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_reactivo' },
        { text: 'MUESTRA ASOCIADA', align: 'start', value: 'muestra_asociada' },
        { text: 'NOTAS', align: 'start', value: 'notas_reactivo' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO REACTIVO', color: 'primary', accion: 'nuevoReactivo' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR REACTIVO', color: 'primary', accion: 'editarReactivo' },
        { id: 2, nombre: 'QUITAR REACTIVO', color: 'error', accion: 'quitarReactivo' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_reactivo
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoReactivo') {
        this.modoReactivo = 'NUEVO'
        this.dialogReactivo = true
      } else if (accion === 'editarReactivo') {
        this.modoReactivo = 'EDITAR'
        this.dialogReactivo = true
      } else if (accion === 'quitarReactivo') {
        alert('Se quitará éste reactivo como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.items = [
        { idreactivo: 1, nombre_reactivo: 'Formula X', muestra_asociada: 'Orina', notas_reactivo: 'Para exámenes de orina' },
        { idreactivo: 2, nombre_reactivo: 'Zepidor 12', muestra_asociada: 'Sangre', notas_reactivo: 'Para exámenes de sangre.' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
