<template>
  <div id="usuarios">
    <MenuBase
      :tipo="'usuarios'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-clipboard-account'"
    />
  <NuevoUsuario :dialogNU.sync="dialogNuevoUsuario"/>
  <EditarUsuario :dialogEU.sync="dialogEditarUsuario"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import NuevoUsuario from '@/views/Usuario/nuevousuario.vue'
import EditarUsuario from '@/views/Usuario/editarusuario.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    NuevoUsuario,
    EditarUsuario
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Usuarios',
    dialog: false,
    tituloOpciones: 'Opciones cuenta: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogNuevoUsuario: false,
    dialogEditarUsuario: false
  }),
  methods: {
    examenes () {
      this.headers = [
        { text: 'TIPO', align: 'start', value: 'tipo_cuenta' },
        { text: 'PERSONA', align: 'start', value: 'nombre_persona' },
        { text: 'SUCURSAL', align: 'start', value: 'sucursal_cuenta' },
        { text: 'USUARIO', align: 'start', value: 'usuario_cuenta' }
      ]
      this.botones = [
        { id: 1, nombre: 'DATOS PROPIOS', color: 'primary', accion: 'datosPropios' },
        { id: 2, nombre: 'NUEVO USUARIO', color: 'primary', accion: 'nuevoUsuario' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR USUARIO', color: 'primary', accion: 'editarUsuario' },
        { id: 2, nombre: 'QUITAR USUARIO', color: 'error', accion: 'quitarUsuario' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.usuario_cuenta
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoUsuario') {
        this.dialogNuevoUsuario = true
      } else if (accion === 'editarUsuario') {
        this.dialogEditarUsuario = true
      } else if (accion === 'quitarUsuario') {

      } else if (accion === 'datosPropios') {
        this.dialogEditarUsuario = true
      }
    },
    cargarExamenes () {
      this.items = [
        { idcuenta: 1, tipo_cuenta: 'Administrador', nombre_persona: 'Bastian Fernández Palma', sucursal_cuenta: 'La Ligua', usuario_cuenta: 'basstox' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.examenes()
      this.cargarExamenes()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
