<template>
    <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
    >
      <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogInput = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
      </v-card>
    <v-row no-gutters class="text-center mb-12" wrap align="center">
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          class="mx-auto"
          outlined
          tile
          elevation="5"
        >
        <v-card-text>
          <p class="display-2 primary--text font-weight-regular">{{titulo}} - {{datosbase[0].examen}}</p>
          <p class="display-1 primary--text font-weight-regular" style="margin-top: -10px;">Paciente: {{datosbase[0].paciente}}</p>
          <p class="headline primary--text font-weight-regular mb-6" style="margin-top: -15px;">Estado Informe: {{datosbase[0].estado}}</p>
          <v-form
            ref="form"
          >
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-text-field v-for="text in resultado1" v-bind:key="text.id"
                  :label=text.parametro
                  v-model="text.resultado"
                  style="margin-bottom: -15px"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
              <v-text-field v-for="text in resultado2" v-bind:key="text.id"
                :label=text.parametro
                v-model="text.resultado"
                style="margin-bottom: -15px"
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
              <v-text-field v-for="text in resultado3" v-bind:key="text.id"
                :label=text.parametro
                v-model="text.resultado"
                style="margin-bottom: -15px"
              ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end mb-3">
          <v-btn
            color="primary"
            @click="limpiar"
            class="mr-1"
          >
          LIMPIAR
          </v-btn>
          <v-btn
            color="primary"
            @click="guardar"
            class="mr-1"
          >
          GUARDAR
          </v-btn>
          <v-btn
            color="primary"
            @click="guardarImprimir"
            class="mr-1"
          >
          GUARDAR E IMPRIMIR
          </v-btn>
          <v-btn
            color="primary"
            @click="guardarEmail"
            class="mr-1"
          >
          GUARDAR Y ENVIAR POR EMAIL
          </v-btn>
          <v-btn
            color="primary"
            @click="guardarYSiguiente"
            class="mr-1"
          >
          GUARDAR Y SIGUIENTE
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'

export default {
  data: () => ({
    titulo: 'Informar Exámen',
    datosbase: [
      { paciente: '', examen: '', estado: '' }
    ],
    resultados: [],
    resultado1: [],
    resultado2: [],
    resultado3: [],
    modo: 'nuevo'
  }),
  mixins: [
    Globales
  ],
  props: ['dialogIE'],
  methods: {
    cargarDatosBase () {
      this.datosbase = []
      this.datosbase = [
        { paciente: 'Bastian Fernández Palma', examen: 'Perfil Bioquímico', estado: 'Muestra Recibida' }
      ]
      // corroborar estado. Si es mayor a Muestra Recibida agregar '- Editando'
    },
    cargarResultados () {
      // dividir resultado hasta en 3 columnas de 7, según cantidad, en arrays resultadox
      // resultados se carga desde BD
      this.resultados = [
        { id: 1, parametro: 'Parámetro', resultado: 1 },
        { id: 2, parametro: 'Parámetro', resultado: 2 },
        { id: 3, parametro: 'Parámetro', resultado: 3 },
        { id: 4, parametro: 'Parámetro', resultado: 4 },
        { id: 5, parametro: 'Parámetro', resultado: 5 },
        { id: 6, parametro: 'Parámetro', resultado: 6 },
        { id: 7, parametro: 'Parámetro', resultado: 7 },
        { id: 8, parametro: 'Parámetro', resultado: 8 },
        { id: 9, parametro: 'Parámetro', resultado: 9 },
        { id: 10, parametro: 'Parámetro', resultado: 10 },
        { id: 11, parametro: 'Parámetro', resultado: 11 },
        { id: 12, parametro: 'Parámetro', resultado: 12 },
        { id: 13, parametro: 'Parámetro', resultado: 13 },
        { id: 14, parametro: 'Parámetro', resultado: 14 },
        { id: 15, parametro: 'Parámetro', resultado: 15 },
        { id: 16, parametro: 'Parámetro', resultado: 16 },
        { id: 17, parametro: 'Parámetro', resultado: 17 },
        { id: 18, parametro: 'Parámetro', resultado: 18 },
        { id: 19, parametro: 'Parámetro', resultado: 19 },
        { id: 20, parametro: 'Parámetro', resultado: 20 },
        { id: 21, parametro: 'Parámetro', resultado: 21 }
      ]
      var index = 0
      while (this.resultados.length > index) {
        if (index < 7) {
          this.resultado1.push(this.resultados[index])
        } else if (index > 6 & index < 14) {
          this.resultado2.push(this.resultados[index])
        } else if (index > 13) {
          this.resultado3.push(this.resultados[index])
        }
        index++
      }
    },
    limpiar () {
      var index = 0
      while (this.resultado1.length > index) {
        this.resultado1[index].resultado = ''
        index++
      }
      index = 0
      while (this.resultado2.length > index) {
        this.resultado2[index].resultado = ''
        index++
      }
      index = 0
      while (this.resultado3.length > index) {
        this.resultado3[index].resultado = ''
        index++
      }
    },
    guardar () {
      alert('Informe guardado')
      this.datosbase[0].estado = 'Guardado - Editando'
    },
    guardarImprimir () {
      this.guardar()
      alert('Se muestran opciones de impresión...')
      this.datosbase[0].estado = 'Informado - Editando'
    },
    guardarEmail () {
      this.guardar()
      alert('Enviando examen por email al paciente...')
      this.datosbase[0].estado = 'Informado - Editando'
    },
    guardarYSiguiente () {
      this.guardar()
      alert('Se continúa con el siguiente exámen pendiente del paciente :)')
      this.datosbase[0].estado = 'Informado - Editando'
    },
    volver () {
      this.$router.push('informesexamenes')
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.cargarDatosBase()
      this.cargarResultados()
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogIE
      },
      set: function (newValue) {
        this.$emit('update:dialogIE', newValue)
      }
    }
  },
  watch: {
    dialogIE () {
      if (this.dialogIE === false) {
        this.resultados = []
        this.resultado1 = []
        this.resultado2 = []
        this.resultado3 = []
      } else {
        this.cargarDatos()
      }
    }
  }
}
</script>

<style scoped>
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .theme--light.v-chip  {
      font-size: 1.2rem !important;
      font-weight:500;
      color: white;
      background-color: #1976d2 !important;
  }
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .v-chip .v-chip__close.v-icon.v-icon--right  {
      color: white;
  }
</style>
