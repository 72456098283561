<template>
  <div id="informesexamenes">
    <MenuBase
      :tipo="'informesexamenes'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      :multiselect="multiselect"
      :multis="multis"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      @quitar-multi="quitarMulti"
      :icono="'mdi-clipboard-text'"
    />
    <VerInforme :dialogVI.sync="dialogVerInforme"/>
    <InformarExamen :dialogIE.sync="dialogInformarExamen"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import VerInforme from '@/views/InformesExamen/verinformeexamen.vue'
import InformarExamen from '@/views/InformesExamen/informarexamen.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    VerInforme,
    InformarExamen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Informes de Exámenes',
    dialog: false,
    tituloOpciones: 'Opciones Informe Exámen: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    multiselect: false,
    multis: [],
    multivisible: false,
    dialogVerInforme: false,
    dialogInformarExamen: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'N° ORDEN', align: 'start', value: 'norden_informe' },
        { text: 'EXAMEN', align: 'start', value: 'nombre_examen' },
        { text: 'ESTADO', align: 'start', value: 'estado_informe' },
        { text: 'RUT PACIENTE', align: 'start', value: 'rut_paciente' },
        { text: 'NOMBRE PACIENTE', align: 'start', value: 'nombre_paciente' }
      ]
      this.botones = [
        { id: 1, nombre: 'ENVIAR POR EMAIL', color: 'primary', accion: 'enviarMailInformeVarios' }

      ]
      this.opciones = [
        { id: 1, nombre: 'VER INFORME', color: 'primary', accion: 'verInforme' },
        { id: 2, nombre: 'INFORMAR o EDITAR', color: 'primary', accion: 'informarEditarInforme' },
        { id: 3, nombre: 'IMPRIMIR INFORME', color: 'primary', accion: 'imprimirInforme' },
        { id: 4, nombre: 'ENVIAR POR EMAIL', color: 'primary', accion: 'enviarMailInforme' }
      ]
    },
    agregarMulti (campo1, campo2) {
      var index = 0
      var existe = 0
      this.campoOpcion = 'N° Orden: ' + campo1 + ' - Paciente: ' + campo2
      while (this.multis.length > index) {
        if (this.multis[index] === this.campoOpcion) {
          existe++
          index = this.multis.length
        }
        index++
      }
      if (existe === 0) {
        this.multis.push(this.campoOpcion)
      } else if (existe > 0) {
        alert('Ya se agregó esta orden de exámen.')
      }
    },
    quitarMulti (item) {
      this.multis = this.multis.filter(it => it !== item)
    },
    clicFila (fila) {
      // store debe almacenar el id
      if (this.multiselect === true) {
        this.agregarMulti(fila.norden_informe, fila.nombre_paciente)
      } else {
        this.campoOpcion = fila.nombre_examen
      }
    },
    clicBoton (accion) {
      if (accion === 'enviarMailInformeVarios') {
        alert('Seleccione los exámenes que desea enviar por Email (se agruparán por N° Orden)')
        this.multiselect = true
      } else if (accion === 'verInforme') {
        this.dialogVerInforme = true
      } else if (accion === 'informarEditarInforme') {
        this.dialogInformarExamen = true
      } else if (accion === 'imprimirInforme') {

      } else if (accion === 'enviarMailInforme') {

      } else if (accion === 'confirmarEnvioMultiEmail') {
        alert('Enviando informes por correo')
      } else if (accion === 'cancelarMultiEmail') {
        this.multiselect = false
        this.multis = []
      } else if (accion === 'quitarMulti') {
        //
      }
    },
    cargarItems () {
      this.items = [
        { idinforme: 1, norden_informe: '1111', nombre_examen: 'Perfil Bioquímico', estado_informe: 'Pendiente', rut_paciente: '16.141.741-6', nombre_paciente: 'Bastian Fernández Palma' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
