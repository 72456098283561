<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    :retain-focus="false"
    width="500"
  >
    <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
      <v-form
        ref="form"
      >
        <v-text-field
          label="Nombre"
          v-model="nombre"
          style="margin-bottom: -5px;"
          required
        ></v-text-field>
        <v-textarea
          v-model="notas"
          auto-grow
          filled
          label="Notas"
          rows="2"
          required
          class="mt-6"
        ></v-textarea>
        <v-row justify="center">
          <v-btn
            color="primary"
          >
          GUARDAR
          </v-btn>
        </v-row>
      </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    titulo: 'Tipo Exámen',
    nombre: '',
    notas: ''
  }),
  props: ['modo', 'dialogTE'],
  methods: {
    reset () {
      this.nombre = ''
      this.notas = ''
    },
    datos () {
      this.nombre = 'Bioquimico'
      this.notas = 'bla bla bla'
    },
    guardarDatos () {
      //
    }
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogTE
      },
      set: function (newValue) {
        this.$emit('update:dialogTE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialogTE () {
      if (this.dialogTE === false) {
        //
      } else {
        //
      }
    },
    modo () {
      if (this.modo === 'EDITAR') {
        this.datos()
      } else {
        this.reset()
      }
    }
  }
}
</script>
