<template>
  <div id="plantillas_examen">
    <MenuBase
      :tipo="'plantillasexamen'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-clipboard-text-multiple'"
    />
    <PlantillaExamen :modo.sync="modoPlantillaExamen" :dialogPE.sync="dialogPlantillaExamen"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import PlantillaExamen from '@/views/plantillaexamen.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    PlantillaExamen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Plantillas de Exámenes',
    dialog: false,
    tituloOpciones: 'Opciones plantilla: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoPlantillaExamen: 'NUEVA',
    dialogPlantillaExamen: false
  }),
  methods: {
    examenes () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_plantilla' },
        { text: 'NOTAS', align: 'start', value: 'notas_plantilla' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVA PLANTILLA', color: 'primary', accion: 'nuevaPlantilla' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR PLANTILLA', color: 'primary', accion: 'editarPlantilla' },
        { id: 2, nombre: 'QUITAR PLANTILLA', color: 'error', accion: 'quitarPlantilla' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_plantilla
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevaPlantilla') {
        this.modoPlantillaExamen = 'NUEVA'
        this.dialogPlantillaExamen = true
      } else if (accion === 'editarPlantilla') {
        this.modoPlantillaExamen = 'EDITAR'
        this.dialogPlantillaExamen = true
      } else if (accion === 'quitarPlantilla') {

      }
    },
    cargarExamenes () {
      this.items = [
        { idplantilla: 1, nombre_plantilla: 'Perfil Bioquímico', notas_plantilla: 'Selección de exámenes bioquímicos' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.examenes()
      this.cargarExamenes()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
