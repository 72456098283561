<template>
  <div id="examenes">
    <MenuBase
      :tipo="'examenes'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-test-tube'"
    />
    <Examen :modo="modoExamen" :dialogE.sync="dialogExamen"/>
    <v-dialog
      v-model="dialogOpcionesOtros"
      persistent
      :retain-focus="false"
      width="400"
    >
      <v-card class="mt-1" style="border-radius: 0px;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogOpcionesOtros = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row
            justify="center"
            align="center"
            class="mb-2"
          >
            <v-btn v-for="opcion in opcionesOtros" v-bind:key="opcion.id"
              :color="opcion.color"
              class="mt-5 mr-1"
              @click="clicBoton(opcion.accion)"
              style="width: 250px;"
            >
              {{opcion.nombre}}
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import Examen from '@/views/examen.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    Examen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Exámenes',
    tituloOpciones: 'Opciones Exámen: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    dialogExamen: false,
    modoExamen: 'NUEVO',
    opcionesOtros: [],
    dialogOpcionesOtros: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_examen' },
        { text: 'TIPO', align: 'start', value: 'tipo_examen' },
        { text: 'MÉTODO', align: 'start', value: 'metodo_examen' },
        { text: 'MUESTRA', align: 'start', value: 'muestra_examen' },
        { text: 'REACTIVO', align: 'start', value: 'reactivo_examen' },
        { text: 'PLANTILLA', align: 'start', value: 'plantilla_examen' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO EXÁMEN', color: 'primary', accion: 'nuevoExamen' },
        { id: 2, nombre: 'COMPONENTES', color: 'primary', accion: 'opcionesOtros' }
      ]
      this.opciones = [
        { id: 1, nombre: 'VER/EDITAR EXÁMEN', color: 'primary', accion: 'editarExamen' },
        { id: 2, nombre: 'QUITAR EXÁMEN', color: 'error', accion: 'quitarExamen' }
      ]
      this.opcionesOtros = [
        { id: 1, nombre: 'TIPOS EXÁMEN', color: 'primary', accion: 'tiposExamen' },
        { id: 2, nombre: 'MUESTRAS', color: 'primary', accion: 'muestras' },
        { id: 3, nombre: 'REACTIVOS', color: 'primary', accion: 'reactivos' },
        { id: 4, nombre: 'MÉTODOS', color: 'primary', accion: 'metodos' },
        { id: 5, nombre: 'MEDIDAS', color: 'primary', accion: 'medidas' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_examen
    },
    clicBoton (accion) {
      if (accion === 'nuevoExamen') {
        this.modoExamen = 'NUEVO'
        this.dialogExamen = true
      } else if (accion === 'editarExamen') {
        this.modoExamen = 'EDITAR'
        this.dialogExamen = true
      } else if (accion === 'quitarExamen') {
        alert('Se quitará el exámen de la lista de disponibles para asignar.')
      } else if (accion === 'opcionesOtros') {
        this.dialogOpcionesOtros = true
      } else if (accion === 'tiposExamen') {
        this.$router.push('tiposexamen')
      } else if (accion === 'muestras') {
        this.$router.push('muestras')
      } else if (accion === 'reactivos') {
        this.$router.push('reactivos')
      } else if (accion === 'metodos') {
        this.$router.push('metodos')
      } else if (accion === 'medidas') {
        this.$router.push('medidas')
      }
    },
    cargarItems () {
      this.items = [
        { idexamen: 1, nombre_examen: 'Glicemia', tipo_examen: 'Bioquímico', metodo_examen: 'Sangre X', muestra_examen: 'Sangre', reactivo_examen: 'Formula X', plantilla_examen: 'Base' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
