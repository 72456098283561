import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menuvisible: false,
    itemsmenu: [],
    medidas: [],
    metodos: [],
    reactivos: [],
    tiposexamen: [],
    muestras: [],
    sucursales: [],
    usuarios: [],
    examenes: []
  },
  mutations: {
    cambiarMenuVisible (state, visible) {
      state.menuvisible = visible
    },
    cambiarItemsMenu (state, itemsmenu) {
      state.itemsmenu = itemsmenu
    },
    cambiarMedidas (state, medidas) {
      state.medidas = medidas
    },
    cambiarMetodos (state, metodos) {
      state.metodos = metodos
    },
    cambiarReactivos (state, reactivos) {
      state.reactivos = reactivos
    },
    cambiarTiposExamen (state, tiposexamen) {
      state.tiposexamen = tiposexamen
    },
    cambiarMuestras (state, muestras) {
      state.muestras = muestras
    },
    cambiarSucursales (state, sucursales) {
      state.sucursales = sucursales
    },
    cambiarUsuarios (state, usuarios) {
      state.usuarios = usuarios
    },
    cambiarExamenes (state, examenes) {
      state.examenes = examenes
    }
  },
  actions: {
  },
  modules: {
  }
})
