<script>
// @ is an alias to /src
// Componentes asociados: recuperarPass, registrarPaciente.
export default {
  data: () => ({
    itemsMenu: []
  }),
  methods: {
    enSesion (tipoPagina) {
      if (this.$store.state.menuvisible === false & tipoPagina === 'sitio') {
        this.itemsMenu = [
          { nombre: 'MENU PRINCIPAL', icono: 'mdi-menu', goto: 'menu' },
          { nombre: 'PACIENTES', icono: 'mdi-account', goto: 'pacientes' },
          { nombre: 'INFORMES DE EXÁMENES', icono: 'mdi-clipboard-text', goto: 'informesexamenes' },
          { nombre: 'EXÁMENES', icono: 'mdi-test-tube', goto: 'examenes' },
          { nombre: 'PLANTILLAS DE EXÁMEN', icono: 'mdi-clipboard-text-multiple', goto: 'plantillasexamen' },
          { nombre: 'LABORATORIOS Y SUCURSALES', icono: 'mdi-home', goto: 'laboratorios' },
          { nombre: 'USUARIOS', icono: 'mdi-clipboard-account', goto: 'usuarios' },
          { nombre: 'CERRAR SESIÓN', icono: 'mdi-close-box', goto: '/' }
        ]
        this.$store.commit('cambiarMenuVisible', true)
        this.$store.commit('cambiarItemsMenu', this.itemsMenu)
      }
    }
  }
}
</script>
