<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    :retain-focus="false"
    width="500"
  >
    <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
      >
      <v-card-text>
        <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
      <v-form
        ref="form"
      >
        <v-text-field
          label="Nombre"
          v-model="nombre"
          style="margin-bottom: -5px;"
          required
        ></v-text-field>
        <MedidaSelect :medida.sync="medida"/>
        <v-text-field
          label="Referencia"
          v-model="referencia"
          style="margin-bottom: -5px;"
          required
        ></v-text-field>
        <MetodoSelect :metodo.sync="metodo"/>
        <ReactivoSelect :reactivo.sync="reactivo"/>
        <TipoExamenSelect :tipoexamen.sync="tipoexamen"/>
        <v-textarea
          v-model="notas"
          auto-grow
          filled
          label="Notas"
          rows="2"
          required
          class="mt-6"
        ></v-textarea>
        <v-row justify="center">
          <v-btn
            color="primary"
          >
          GUARDAR
          </v-btn>
        </v-row>
      </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MedidaSelect from '@/components/medidaselect.vue'
import MetodoSelect from '@/components/metodoselect.vue'
import ReactivoSelect from '@/components/reactivoselect.vue'
import TipoExamenSelect from '@/components/tipoexamenselect.vue'

export default {
  data: () => ({
    titulo: 'Exámen',
    nombre: '',
    referencia: '',
    notas: '',
    medida: '',
    metodo: '',
    reactivo: '',
    tipoexamen: ''
  }),
  components: {
    MedidaSelect,
    MetodoSelect,
    ReactivoSelect,
    TipoExamenSelect
  },
  props: ['modo', 'dialogE'],
  methods: {
    reset () {
      this.nombre = ''
      this.referencia = ''
      this.notas = ''
      this.medida = ''
      this.metodo = ''
      this.reactivo = ''
      this.tipoexamen = ''
    },
    datos () {
      this.nombre = 'Glicemia'
      this.referencia = '3.3'
      this.notas = 'Para medir glucosa en sangre'
      this.medida = 'Centimetros'
      this.metodo = 'Batir'
      this.reactivo = 'Químico 2'
      this.tipoexamen = 'Bioquimico'
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      if (this.modoInput === 'EDITAR') {
        this.datos()
      } else {
        this.reset()
      }
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogE
      },
      set: function (newValue) {
        this.$emit('update:dialogE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialogE () {
      if (this.dialogE === false) {
        //
      } else {
        this.cargarDatos()
      }
    }
  }
}
</script>
