<template>
  <div id="plantillaexamen">
    <v-dialog
      v-model="dialogInput"
      persistent
      :retain-focus="false"
      width="800"
    >
      <v-card class="mt-1" style="border-radius: 0px; background-color: cornflowerblue;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogInput = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
      </v-card>
      <v-card
          class="mx-auto"
          outlined
          tile
          elevation="5"
        >
        <v-card-text>
          <p class="display-1 primary--text font-weight-regular mt-2"> {{modoInput}} {{titulo}}</p>
        <v-form
          ref="form"
        >
        <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                label="Nombre Plantilla"
                v-model="nombre"
                style="margin-bottom: -5px;"
                required
              ></v-text-field>
              <v-autocomplete
                label="Visibilidad"
                :items="visibleOpciones"
                v-model="visibleSelect"
                style="margin-bottom: -5px;"
                required
              ></v-autocomplete>
              <v-textarea
                v-model="notas"
                auto-grow
                filled
                label="Notas"
                rows="3"
                required
                class="mt-1"
              ></v-textarea>
              <ExamenSelect :examen.sync="examen"/>
              <v-row justify="center mb-4">
                <v-btn color="primary" class="mr-1" @click="verDialogCopiarExamen()">COPIAR</v-btn>
                <v-btn color="primary" @click="agregarExamen()">AGREGAR</v-btn>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-list dense>
                <v-subheader class="headline primary--text">Exámenes de esta plantilla</v-subheader>
                <v-list-item-group
                v-model="examenSeleccionado"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in examenes"
                    :key="i"
                    style="border: 1px solid Gainsboro"
                    class="primary--text"
                    @click="quitarExamen(i)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon" class="primary--text"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.nombre" class="text-left subtitle-1 primary--text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <v-row justify="center mt-10">
            <v-btn
              color="primary"
            >
            GUARDAR
            </v-btn>
          </v-row>
        </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogCopiarExamen"
      persistent
      :retain-focus="false"
      width="400"
    >
      <v-card class="mt-1" style="border-radius: 0px;">
        <v-card-actions>
          <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
          <v-btn
            color="error"
            dark
            fab
            small
            @click="dialogCopiarExamen = false"
          >
          <v-icon dark>
            mdi-close
          </v-icon>
          </v-btn>
        </v-row>
        </v-card-actions>
        <v-card-text>
          <p class="headline primary--text font-weight-regular">Copiando exámen: {{examen}}</p>
          <v-text-field
            label="Nuevo nombre exámen copia"
            v-model="copiaExamen"
            style="margin-bottom: -5px;"
            required
          ></v-text-field>
          <v-row justify="center">
            <v-btn color="primary" @click="copiarExamen">COPIAR</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ExamenSelect from '@/components/examenselect.vue'

export default {
  data: () => ({
    titulo: 'Plantilla Exámen',
    visibleOpciones: ['Visible', 'Escondido'],
    visibleSelect: 'Visible',
    nombre: '',
    examen: '',
    notas: '',
    examenes: [],
    dialogCopiarExamen: false,
    copiaExamen: '',
    examenSeleccionado: null
  }),
  components: {
    ExamenSelect
  },
  props: ['modo', 'dialogPE'],
  methods: {
    reset () {
      this.nombre = ''
      this.notas = ''
      this.visibleSelect = 'Visible'
      this.examen = ''
      this.examenes = []
    },
    datos () {
      this.nombre = 'Perfil Bioquímico'
      this.notas = 'Set de exámenes bioquímicos'
      this.visibleSelect = 'Visible'
      this.examenes = [
        { id: 1, nombre: 'Uremia', icon: 'mdi-test-tube' },
        { id: 2, nombre: 'Triglicéridos', icon: 'mdi-test-tube' }
      ]
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      if (this.modoInput === 'EDITAR') {
        this.datos()
      } else {
        this.reset()
      }
    },
    verDialogCopiarExamen () {
      if (this.examen !== '') {
        this.dialogCopiarExamen = true
      } else {
        alert('Debe seleccionar un exámen primero.')
      }
    },
    copiarExamen () {
      this.examenes.push({ id: 3, nombre: this.copiaExamen, icon: 'mdi-test-tube' })
      this.dialogCopiarExamen = false
    },
    agregarExamen () {
      this.examenes.push({ id: 3, nombre: this.examen, icon: 'mdi-test-tube' })
    },
    compruebaExamenAgregado () {
      //
    },
    quitarExamen (index) {
      this.examenes.splice(index, 1)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogPE
      },
      set: function (newValue) {
        this.$emit('update:dialogPE', newValue)
      }
    },
    modoInput: {
      get: function () {
        return this.modo
      },
      set: function (newValue) {
        this.$emit('update:modo', newValue)
      }
    }
  },
  watch: {
    dialogPE () {
      if (this.dialogPE === false) {
        //
      } else {
        this.cargarDatos()
      }
    }
  }
}
</script>
