<template>
  <v-row>
    <v-autocomplete
      label="Sucursal"
      :items="$store.state.sucursales"
      v-model="sucursalInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
    </v-btn>
    <Sucursal :modo.sync="modoSucursal" :dialogSu.sync="dialogSucursal"/>
  </v-row>
</template>

<script>
import Sucursal from '@/views/sucursal.vue'

export default {
  data: () => ({
    modoSucursal: 'NUEVA',
    dialogSucursal: false
  }),
  props: ['sucursal'],
  components: {
    Sucursal
  },
  methods: {
    cargarNuevoEditar () {
      if (this.sucursalInput === '' | this.sucursalInput === null) {
        this.modoSucursal = 'NUEVA'
      } else {
        this.modoSucursal = 'EDITAR'
      }
      this.dialogSucursal = true
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var sucursales = ['AFLAB LTDA.']
      this.$store.commit('cambiarSucursales', sucursales)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    sucursalInput: {
      get: function () {
        return this.sucursal
      },
      set: function (newValue) {
        this.$emit('update:sucursal', newValue)
      }
    }
  }
}
</script>
