<template>
  <v-row>
    <v-autocomplete
      label="Método"
      :items="$store.state.metodos"
      v-model="metodoInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
    </v-btn>
    <Metodo :modo.sync="modoMetodo" :dialogMET.sync="dialogMetodo"/>
  </v-row>
</template>

<script>
import Metodo from '@/views/metodo.vue'

export default {
  data: () => ({
    modoMetodo: 'NUEVO',
    dialogMetodo: false
  }),
  props: ['metodo'],
  components: {
    Metodo
  },
  methods: {
    cargarNuevoEditar () {
      if (this.metodoInput === '' | this.metodoInput === null) {
        this.modoMetodo = 'NUEVO'
      } else {
        this.modoMetodo = 'EDITAR'
      }
      this.dialogMetodo = true
    },
    guardarDatos () {
      //
    },
    cargarDatos () {
      var metodos = ['Luz 1', 'Batir']
      this.$store.commit('cambiarMetodos', metodos)
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    metodoInput: {
      get: function () {
        return this.metodo
      },
      set: function (newValue) {
        this.$emit('update:metodo', newValue)
      }
    }
  }
}
</script>
