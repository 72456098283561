<template>
  <v-dialog
    v-model="dialogInput"
    persistent
    :retain-focus="false"
  >
    <v-card class="mt-2" style="border-radius: 0px; background-color: cornflowerblue;">
      <v-card-actions>
        <v-row align="center" justify="end" class="cornflowerblue" style="z-index: 9999;">
        <v-btn
          color="error"
          dark
          fab
          small
          @click="dialogInput = false"
        >
        <v-icon dark>
          mdi-close
        </v-icon>
        </v-btn>
      </v-row>
      </v-card-actions>
    </v-card>
    <v-row no-gutters class="text-center" wrap align="center">
      <v-col
        cols="12"
        sm="12"
      >
        <v-card
          class="mx-auto"
          outlined
          tile
          elevation="5"
        >
        <v-card-text>
          <p class="display-2 primary--text font-weight-regular">{{titulo}}</p>
          <p class="display-1 primary--text font-weight-regular mb-5" style="margin-top: -10px;">Paciente: {{paciente}}</p>
          <v-form
            ref="form"
          >
            <v-row class="text-left pl-5 pr-5 mb-5">
              <v-col
                cols="12"
                sm="12"
              >
              <v-autocomplete
                label="SELECCIONE EXÁMENES PARA ASIGNAR"
                :items="examenes"
                v-model="examenesok"
                chips
                multiple
                deletable-chips
                required
              ></v-autocomplete>
                <v-row align="center" justify="center">
                <v-btn
                  color="error"
                  @click="preQuitarTodo"
                >
                QUITAR TODOS
                </v-btn>
              </v-row>
              </v-col>
            </v-row>
            <v-row class="text-left pl-5 pr-5">
              <v-col
                cols="12"
                sm="6"
              >
              <v-text-field
                v-model="medico"
                label="Médico Solicitante"
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
              <v-textarea
                v-model="notas"
                auto-grow
                filled
                label="Notas"
                rows="3"
              ></v-textarea>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" style="margin-bottom: 40px;">
              <v-btn
                color="primary"
                class="mt-3 mr-2"
                @click="guardarDatos"
              >
              GUARDAR
              </v-btn>
              <v-btn
                color="primary"
                class="mt-3"
                @click="guardarRecibirMuestras"
              >
              GUARDAR Y RECIBIR MUESTRAS
              </v-btn>
            </v-row>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog
        v-model="dialog"
        width="350"
        persistent
      >
        <v-card>
          <v-card-title class="headline blue--text justify-center" style="word-break: break-word;">
            ¿Está seguro que desea quitar todos los exámenes ya seleccionados?
          </v-card-title>
          <v-card-text>
            <v-row
              justify="center"
              align="center"
              style="padding: 10px;"
            >
            <v-btn
                color="primary"
                class="mt-3 mr-2"
                @click="dialog = false"
              >
              NO
              </v-btn>
              <v-btn
                color="error"
                class="mt-3"
                @click="quitarTodo"
              >
              SI
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-dialog>
</template>

<script>
import Globales from '@/components/globales.vue'

export default {
  data: () => ({
    titulo: 'Asignar Exámenes',
    paciente: 'Bastian Fernández Palma',
    examenes: ['Glicemia', 'Perfil Bioquímico', 'Perfil Lipídico', 'Hemograma'],
    examenesok: [],
    medico: '',
    notas: '',
    dialog: false
  }),
  mixins: [
    Globales
  ],
  props: ['dialogAE'],
  methods: {
    preQuitarTodo () {
      this.dialog = true
    },
    quitarTodo () {
      this.examenesok = []
      this.dialog = false
    },
    cargarExamenes () {
      //
    },
    guardarDatos () {
      //
    },
    guardarRecibirMuestras () {
      //
    },
    volver () {
      this.$router.push('pacientes')
    },
    cargarDatos () {
      this.enSesion('sitio')
    }
  },
  created () {
    // this.cargarDatos()
  },
  computed: {
    dialogInput: {
      get: function () {
        return this.dialogAE
      },
      set: function (newValue) {
        this.$emit('update:dialogAE', newValue)
      }
    }
  },
  watch: {
    dialogAE () {
      if (this.dialogAE === false) {
        //
      }
    }
  }
}
</script>

<style scoped>
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .theme--light.v-chip  {
      font-size: 1.2rem !important;
      font-weight:500;
      color: white;
      background-color: #1976d2 !important;
  }
  .v-autocomplete.v-autocomplete.v-autocomplete >>> .v-chip .v-chip__close.v-icon.v-icon--right  {
      color: white;
  }
</style>
