<template>
  <div id="tiposexamen">
    <MenuBase
      :tipo="'tiposexamen'"
      :headers="headers"
      :items="items"
      :botones="botones"
      :opciones="opciones"
      :alineaHeaders='alineaHeaders'
      :titulo="titulo"
      :tituloOpciones="tituloOpciones"
      :campoOpcion="campoOpcion"
      @clic-boton="clicBoton"
      @clic-fila="clicFila"
      :icono="'mdi-test-tube-empty'"
    />
    <TipoExamen :modo="modoTipoExamen" :dialogTE.sync="dialogTipoExamen"/>
  </div>
</template>

<script>
import Globales from '@/components/globales.vue'
import MenuBase from '@/components/menubase.vue'
import TipoExamen from '@/views/tipoexamen.vue'

export default {
  mixins: [
    Globales
  ],
  components: {
    MenuBase,
    TipoExamen
  },
  data: () => ({
    headers: [],
    items: [],
    botones: [],
    opciones: [],
    titulo: 'Tipos Exámen',
    dialog: false,
    tituloOpciones: 'Opciones método: ',
    campoOpcion: '',
    alineaHeaders: 'start',
    modoTipoExamen: 'NUEVO',
    dialogTipoExamen: false
  }),
  methods: {
    interfaz () {
      this.headers = [
        { text: 'NOMBRE', align: 'start', value: 'nombre_tipoexamen' },
        { text: 'NOTAS', align: 'start', value: 'notas_tipoexamen' }
      ]
      this.botones = [
        { id: 1, nombre: 'NUEVO TIPO EXÁMEN', color: 'primary', accion: 'nuevoTipoExamen' }
      ]
      this.opciones = [
        { id: 1, nombre: 'EDITAR TIPO EXÁMEN', color: 'primary', accion: 'editarTipoExamen' },
        { id: 2, nombre: 'QUITAR TIPO EXÁMEN', color: 'error', accion: 'quitarTipoExamen' }
      ]
    },
    clicFila (fila) {
      // store debe almacenar el id
      this.campoOpcion = fila.nombre_tipoexamen
      // store debe almacenar tipo
      this.dialog = true
    },
    clicBoton (accion) {
      if (accion === 'nuevoTipoExamen') {
        this.modoTipoExamen = 'NUEVO'
        this.dialogTipoExamen = true
      } else if (accion === 'editarTipoExamen') {
        this.modoTipoExamen = 'EDITAR'
        this.dialogTipoExamen = true
      } else if (accion === 'quitarTipoExamen') {
        alert('Se quitará éste tipo exámen como parámetro seleccionable')
      }
    },
    cargarItems () {
      this.items = [
        { idtipoexamen: 1, nombre_tipoexamen: 'Bioquimico', notas_tipoexamen: 'bla bla bla' },
        { idtipoexamen: 2, nombre_tipoexamen: 'Hormonal', notas_tipoexamen: 'ble ble ble' }
      ]
    },
    cargarDatos () {
      this.enSesion('sitio')
      this.interfaz()
      this.cargarItems()
    }
  },
  created: function () {
    this.cargarDatos()
  }
}
</script>

<style scoped>
  .v-data-table.v-data-table.v-data-table >>> td  {
    font-size: 1.0rem !important;
    font-weight:500;
  }
  .v-data-table.v-data-table.v-data-table >>> th  {
    font-size: 1.0rem !important;
    font-weight:500;
    color: cornflowerblue;
  }
</style>
