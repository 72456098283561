<template>
   <v-row no-gutters class="text-center" wrap align="center" style="margin-top: 30px">
    <v-col
      cols="12"
      sm="12"
    >
    <p class="display-2 primary--text font-weight-regular">AFLAB WEB</p>
      <v-card
        class="mx-auto"
        outlined
        tile
        elevation="5"
        max-width="720"
        style="padding: 25px;"
      >
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-autocomplete
            label="Laboratorio"
            :items="labs"
            v-model="lab"
            auto-select-first
            clearable
            required
          ></v-autocomplete>
          <v-text-field
            v-model="usuario"
            label="RUT"
            required
          ></v-text-field>
          <v-text-field
            v-model="pass"
            label="Contraseña"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
            required
          ></v-text-field>
          <v-row
            align="center"
            justify="space-around"
          >
          <v-btn
            color="primary"
            class="mt-5"
            @click="login('paciente')"
          >
            ACCESO PACIENTE
          </v-btn>
          <v-btn
            color="primary"
            class="mt-5"
            @click="login('usuario')"
          >
            ACCESO USUARIO
          </v-btn>
          <v-btn
            color="primary"
            class="mt-5"
            @click="sheet = !sheet"
          >
            + OPCIONES
          </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
      </v-card>
    </v-col>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="280px"
      >
        <v-btn
          class="mt-1"
          text
          color="red"
          @click="sheet = !sheet"
        >
          CERRAR
        </v-btn>
        <div>
          <p>¿Olvidó su contraseña? Recuperela aquí:</p>
          <v-btn
            color="primary"
            @click="recuperarPass()"
          >
            RECUPERAR CONTRASEÑA
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-row>
</template>

<script>
// @ is an alias to /src
// Componentes asociados: recuperarPass, registrarPaciente.
import Globales from '@/components/globales.vue'

export default {
  mixins: [
    Globales
  ],
  data: () => ({
    sheet: false,
    valid: false,
    labs: ['AFLAB LTDA.'],
    lab: '',
    usuario: '',
    pass: '',
    showpass: false,
    itemsMenu: []
  }),
  methods: {
    listarLabs () {
      //
    },
    login (tipo) {
      // codigo ingreso
      this.enSesion('sitio')
      this.$router.push('menu')
    },
    registrarPaciente () {
      //
    },
    recuperarPass () {
      //
    },
    cargarDatos () {
      this.$store.commit('cambiarMenuVisible', false)
    }
  },
  created () {
    this.cargarDatos()
  }
}
</script>
