<template>
  <v-row>
    <v-autocomplete
      label="U. Medida"
      :items="$store.state.medidas"
      v-model="medidaInput"
      clearable
      style="margin-bottom: -5px;"
      required
      class="pl-3 pr-3"
    ></v-autocomplete>
      <v-btn
        color="primary"
        dark
        fab
        class="mr-2"
        @click="cargarNuevoEditar"
      >
      <v-icon dark>
        mdi-select-all
      </v-icon>
     </v-btn>
     <Medida :modo.sync="modoMedida" :dialogME.sync="dialogMedida"/>
  </v-row>
</template>

<script>
import Medida from '@/views/medida.vue'

export default {
  data: () => ({
    modoMedida: 'NUEVA',
    dialogMedida: false
  }),
  props: ['medida'],
  components: {
    Medida
  },
  methods: {
    guardarDatos () {
      //
    },
    cargarDatos () {
      var medidas = ['Centimetros', 'Milimetros']
      this.$store.commit('cambiarMedidas', medidas)
    },
    cargarNuevoEditar () {
      if (this.medidaInput === '' | this.medidaInput === null) {
        this.modoMedida = 'NUEVA'
      } else {
        this.modoMedida = 'EDITAR'
      }
      this.dialogMedida = true
    }
  },
  created: function () {
    this.cargarDatos()
  },
  computed: {
    medidaInput: {
      get: function () {
        return this.medida
      },
      set: function (newValue) {
        this.$emit('update:medida', newValue)
      }
    }
  }
}
</script>
